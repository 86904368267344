// KeyContext.js
import React, { createContext, useState, useContext } from 'react';

// Create the context
const KeyContext = createContext();

// Create a custom hook to use the KeyContext
export const useKeyContext = () => {
  return useContext(KeyContext);
};

// Create a provider component to wrap around your app
export const KeyProvider = ({ children }) => {
  const [Utoken, setUtoken] = useState(null); // Store the key in state

  const updatetoken = (newKey) => {
    setUtoken(newKey); // Update the key when it's validated
  };

  return (
    <KeyContext.Provider value={{ Utoken, updatetoken }}>
      {children}
    </KeyContext.Provider>
  );
};
