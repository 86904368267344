// App.js
import { KeyProvider } from './context/KeyContext';
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Import your page components
import SurveyPage from './pages/survey';
import SurveyValidatorPage from './pages/survey-key-validator';
const router = createBrowserRouter([
    {
        path:'/',
        element:<SurveyValidatorPage />
    },{
        path:'/survey',
        element:<SurveyPage />
    }
])

function App() {
  return (
    <KeyProvider>
      <RouterProvider router={router} />
    </KeyProvider>
    
  );
}

export default App;
