import '../css/Survey.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKeyContext } from '../context/KeyContext'; // Import the useKeyContext hook



export default function SurveyKeyValidator() {  
  const { updatetoken } = useKeyContext();
  const [key, setKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const navigate = useNavigate();

  const handleKeyChange = (e) => {
    setKey(e.target.value);
    setResponseMessage('');
  };

  const validateKey = async () => {
    if (key.length !== 10) {
      setResponseMessage('Key must be 10 characters long.');
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch('https://loadslinks.netlify.app/api/validate-key', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ key }),
      });
      const data = await response.json();
      if (data.isValid) {
        setResponseMessage('Key validated successfully. Redirecting to survey...');
        updatetoken(key);
        navigate('/survey');
      } else if (data.used) {
        setResponseMessage('Thanks for taking the time! Your survey has already been submitted, and we are reviewing your responses.');
      } else {
        setResponseMessage(data.message || 'Invalid key. Please try again.');
      }
    } catch (error) {
      console.error('Error validating key:', error);
      setResponseMessage('An error occurred while validating the key. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div  className="survey-container">
      <div className="survey-content">
        <div className="survey-form">
          <h1  className="survey-title"> 
            WELCOME TO OUR WEBSITE, PLEASE ENTER YOUR UNIQUE KEY TO START THE SURVEY
          </h1>
          <div className="input-container">
            <p className="input-label">
              INSERT YOUR UNIQUE KEY FOUND ON THE LETTER
            </p>
            <input
              className="key-input"
              type="text"
              value={key}
              onChange={handleKeyChange}
              placeholder="Enter 10-character key"
              maxLength={10}
            />
          </div>
          <div className="button-container">
            <button
              className="submit-button"
              onClick={validateKey}
              disabled={isLoading || key.length !== 10}
            >
              {isLoading ? 'Validating...' : 'Start Survey'}
            </button>
          </div>
          {responseMessage && (
            <div className="response-message">
              <p className={responseMessage.includes('Thanks for taking the time') ? 'success' : 'error'}>
                {responseMessage}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}