import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { useCallback } from 'react';
import { useKeyContext } from '../context/KeyContext'; // Import the useKeyContext hook
import { PlainDark } from "survey-core/themes";

const surveyJson = {
  "logoWidth": "400px",
  "logoFit": "none",
  "logoPosition": "right",
  "pages": [
    {
      "name": "booking_frequency_page",
      "title": "Booking Frequency and Shipment Details",
      "elements": [
        {
          "type": "radiogroup",
          "name": "booking_frequency",
          "title": "How often do you book shipments through freight brokers or 3PL providers?",
          "isRequired": true,
          "choices": [
            {
              "value": "daily",
              "text": "Daily"
            },
            {
              "value": "weekly",
              "text": "Weekly"
            },
            {
              "value": "monthly",
              "text": "Monthly"
            },
            {
              "value": "rarely",
              "text": "Rarely"
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "common_shipment_type",
          "title": "What is the most common type of shipment you book through a freight broker/3PL?",
          "isRequired": true,
          "showCommentArea": true,
          "choices": [
            {
              "value": "ftl",
              "text": "Full Truckload (FTL)"
            },
            {
              "value": "ltl",
              "text": "Less than Truckload (LTL)"
            }
          ]
        },
        {
          "type": "checkbox",
          "name": "booking_challenges",
          "title": "What challenges do you most commonly face when booking shipments through a freight broker/3PL? (Select all that apply)",
          "isRequired": true,
          "showCommentArea": true,
          "choices": [
            {
              "value": "lack_of_real_time_tracking",
              "text": "Lack of real-time shipment tracking"
            },
            {
              "value": "difficulty_obtaining_rates",
              "text": "Difficulty in obtaining competitive rates"
            },
            {
              "value": "slow_response_times",
              "text": "Limited communication or slow response times"
            },
            {
              "value": "shipment_delays",
              "text": "Issues with shipment delays or missed deadlines"
            },
            {
              "value": "inaccurate_quotes",
              "text": "Difficulty in getting accurate quotes"
            },
            {
              "value": "paperwork_issues",
              "text": "Inaccurate documentation or paperwork issues"
            },
            {
              "value": "poor_customer_service",
              "text": "Poor customer service or support"
            },
            {
              "value": "tech_platform_issues",
              "text": "Inadequate technology/platform (e.g., booking system issues)"
            }
          ],
          "showSelectAllItem": true
        },
        {
          "type": "rating",
          "name": "booking_process_satisfaction",
          "title": "How satisfied are you with the transparency and visibility of the shipment booking process through freight brokers/3PLs?",
          "isRequired": true,
          "rateValues": [
            {
              "value": 1,
              "text": "Very Satisfied"
            },
            {
              "value": 2,
              "text": "Satisfied"
            },
            {
              "value": 3,
              "text": "Neutral"
            },
            {
              "value": 4,
              "text": "Dissatisfied"
            },
            {
              "value": 5,
              "text": "Very Dissatisfied"
            }
          ]
        },
        {
          "type": "checkbox",
          "name": "improvements_for_booking_process",
          "title": "What improvements or changes would make the booking process with freight brokers/3PLs easier or more efficient for you?",
          "isRequired": true,
          "showCommentArea": true,
          "choices": [
            {
              "value": "better_communication",
              "text": "Better communication and more timely responses"
            },
            {
              "value": "user_friendly_technology",
              "text": "More user-friendly technology/platform"
            },
            {
              "value": "real_time_tracking",
              "text": "Improved visibility and real-time tracking of shipments"
            },
            {
              "value": "competitive_pricing",
              "text": "More accurate and competitive pricing"
            },
            {
              "value": "enhanced_customer_service",
              "text": "Enhanced customer service and support"
            },
            {
              "value": "streamlined_paperwork",
              "text": "Streamlined documentation and paperwork processes"
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "shipment_delays_experienced",
          "title": "Have you encountered issues with shipment delays when booking through freight brokers/3PLs?",
          "isRequired": true,
          "choices": [
            {
              "value": "frequent_delays",
              "text": "Yes, frequently"
            },
            {
              "value": "occasional_delays",
              "text": "Yes, occasionally"
            },
            {
              "value": "no_delays",
              "text": "No, never"
            },
            {
              "value": "not_sure",
              "text": "Not sure"
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "importance_of_integrated_technology",
          "title": "How important is it for you to have access to integrated technology (e.g., TMS, real-time tracking, automated booking) when working with freight brokers/3PLs?",
          "isRequired": true,
          "choices": [
            {
              "value": "very_important",
              "text": "Very important"
            },
            {
              "value": "important",
              "text": "Important"
            },
            {
              "value": "neutral",
              "text": "Neutral"
            },
            {
              "value": "not_important",
              "text": "Not very important"
            },
            {
              "value": "not_important_at_all",
              "text": "Not important at all"
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "consider_switching_providers_for_better_technology",
          "title": "Would you consider using a different freight broker or 3PL if they offered better service or technology where you were directly connected with the truck driver using cutting-edge technologies?",
          "isRequired": true,
          "choices": [
            {
              "value": "yes_definitely",
              "text": "Yes, definitely"
            },
            {
              "value": "maybe_depending_on_offer",
              "text": "Maybe, depending on the offer"
            },
            {
              "value": "no_preference",
              "text": "No, I prefer my current provider"
            },
            {
              "value": "not_sure",
              "text": "Not sure"
            }
          ]
        },
        {
          "type": "comment",
          "name": "additional_challenges_with_brokers_3PLs",
          "title": "Are there any other challenges or issues you'd like to share about working with freight brokers or 3PLs?"
        },
        {
          "type": "radiogroup",
          "name": "accept_updates_and_trials",
          "title": "Are you open to receiving updates or a free trial for a new product coming soon in the freight movement industry?",
          "isRequired": true,
          "choices": [
            {
              "value": "yes",
              "text": "Yes"
            },
            {
              "value": "no",
              "text": "No"
            }
          ]
        },
        {
          "type": "text",
          "name": "email_address",
          "visibleIf": "{accept_updates_and_trials} = 'yes'",
          "title": "Email Address",
          "inputType": "email"
        },
        {
          "type": "text",
          "name": "full_name",
          "title": "Full Name"
        },
        {
          "type": "text",
          "name": "company_name",
          "title": "Company Name"
        }
      ]
    }
  ],
  "cookieName": "COOKIES"
};


function SurveyForm() {
  const { Utoken } = useKeyContext(); // Access the key from context
  const survey = new Model(surveyJson);
  survey.applyTheme(PlainDark);
  console.log(Utoken)
  const alertResults = useCallback(async (sender) => {
    const results = sender.data; // Get the form data as an object

    // Combine the key and form data into a single object
    const dataToSubmit = {
      userKey: Utoken, // Include the key from context
      formData: results // Include the form data
    };

    try {
      // Make a POST request to the API
      const response = await fetch('https://loadslinks.netlify.app/api/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Indicate that we're sending JSON data
        },
        body: JSON.stringify(dataToSubmit), // Send the combined data as JSON
      });

      // Check if the response was successful
      if (!response.ok) {
        throw new Error('Failed to save user data');
      }

      // Parse the response if needed, for example to log or handle success
      const responseData = await response.json();
      console.log('Success:', responseData);

      // Optionally, show a success message or take other actions
      // e.g., set some state, show a success toast, etc.
    } catch (error) {
      // Log the error for debugging
      console.error('Error submitting data:', error);

      // Optionally, show an error message to the user
      // e.g., set some state, show an error toast, etc.
    }
  }, [Utoken]); // Depend on the key to ensure it's updated properly when changing

  survey.onComplete.add(alertResults);

  return <Survey  model={survey} />;
}


export default SurveyForm;